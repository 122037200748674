import { Component, ViewChild } from '@angular/core';

import * as Hammer from 'hammerjs' ;

import { PlyrComponent } from 'ngx-plyr';
import * as Plyr from 'plyr';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Hans Gurbig';

    /** Component Variables */

    imagesSlideShow: any[] = [
      'https://firebasestorage.googleapis.com/v0/b/hansgurbig-de-9fc49.appspot.com/o/pictures%2Fall%2Fslideshow%2Fby%20halbwelt%2F1_zugeschnitten.jpg?alt=media&token=fef9735c-bfef-49a4-9cdb-e8661ec2b747',
      'https://firebasestorage.googleapis.com/v0/b/hansgurbig-de-9fc49.appspot.com/o/pictures%2Fall%2Fslideshow%2Fby%20halbwelt%2F2.jpg?alt=media&token=a4441fcb-b4cf-46f6-8bf2-a8dd3a06cb34'
    ]

    /**
      'https://firebasestorage.googleapis.com/v0/b/hansgurbig-de-9fc49.appspot.com/o/pictures%2Fall%2Fslideshow%2FHG_87_hemd.jpg?alt=media&token=de1d450e-c4f7-446b-8d58-d995b9b53dfa',
      'https://firebasestorage.googleapis.com/v0/b/hansgurbig-de-9fc49.appspot.com/o/pictures%2Fall%2Fslideshow%2FHG_86_hemd.jpg?alt=media&token=27f95af7-037f-43cf-b7f9-2a4254853df0',
      'https://firebasestorage.googleapis.com/v0/b/hansgurbig-de-9fc49.appspot.com/o/pictures%2Fall%2Fslideshow%2FHG_88_hemd.jpg?alt=media&token=ff0622b5-585e-4231-a36f-b2a3eed7cb16',
      'https://firebasestorage.googleapis.com/v0/b/hansgurbig-de-9fc49.appspot.com/o/pictures%2Fall%2Fslideshow%2FHans4sw_desktop.jpg?alt=media&token=261eaaa0-fb20-497d-803a-a9fd3885b7d8'
     */

    imagesSlideShow_mobile: any[] = [
      'https://firebasestorage.googleapis.com/v0/b/hansgurbig-de-9fc49.appspot.com/o/pictures%2Fall%2Fslideshow%2Fby%20halbwelt%2F1_zugeschnitten.jpg?alt=media&token=fef9735c-bfef-49a4-9cdb-e8661ec2b747',
      'https://firebasestorage.googleapis.com/v0/b/hansgurbig-de-9fc49.appspot.com/o/pictures%2Fall%2Fslideshow%2Fby%20halbwelt%2F2.jpg?alt=media&token=a4441fcb-b4cf-46f6-8bf2-a8dd3a06cb34'
    ]

    imageCounter: number = 0;

    imagesGallery_1:  any[] = [
      "'https://firebasestorage.googleapis.com/v0/b/hansgurbig-de-9fc49.appspot.com/o/pictures%2Fall%2Fportraits2021%2F1.%20HG_87_hemd_FAV(headshot).jpg?alt=media&token=03635906-771e-4ac2-845c-88b43cc7ef26'",
      'https://firebasestorage.googleapis.com/v0/b/hansgurbig-de-9fc49.appspot.com/o/pictures%2Fall%2Fportraits2021%2F4.%20HG_80_blauesshirt_FAV.jpg?alt=media&token=022ca434-8234-46cf-ba22-915037349993',
      'https://firebasestorage.googleapis.com/v0/b/hansgurbig-de-9fc49.appspot.com/o/pictures%2Fall%2Fportraits2021%2F3.%20HG_77_blauesshirt_FAV.jpg?alt=media&token=7e783f38-cc58-47a8-a974-3b6616b0f985',
      'https://firebasestorage.googleapis.com/v0/b/hansgurbig-de-9fc49.appspot.com/o/pictures%2Fall%2Fportraits2021%2F7.%20HG_54_mantel_FAV.jpg?alt=media&token=9bc8c4d9-7f2c-46e4-8c21-6fce572b1f86',
      'https://firebasestorage.googleapis.com/v0/b/hansgurbig-de-9fc49.appspot.com/o/pictures%2Fall%2Fportraits2021%2F17.%20HG_43_winterjacke_FAV.jpg?alt=media&token=73ba9619-3ca7-40f1-83be-dc1ef380dc86',
      'https://firebasestorage.googleapis.com/v0/b/hansgurbig-de-9fc49.appspot.com/o/pictures%2Fall%2Fportraits2021%2F10.%20HG_71_anzug_FAV.jpg?alt=media&token=acab77e2-3155-4fd4-aa53-c21dfb38a668',
      'https://firebasestorage.googleapis.com/v0/b/hansgurbig-de-9fc49.appspot.com/o/pictures%2Fall%2Fportraits2021%2F12.3%20HG_112_rasiert.jpg?alt=media&token=52e2a3a6-54b2-4039-92de-fad8d35d50e1',
      'https://firebasestorage.googleapis.com/v0/b/hansgurbig-de-9fc49.appspot.com/o/pictures%2Fall%2Fportraits2021%2F14.%20HG_60_goldhemnd.jpg?alt=media&token=3eac3c86-8087-4a9c-ac5c-082715494e08',
      'https://firebasestorage.googleapis.com/v0/b/hansgurbig-de-9fc49.appspot.com/o/pictures%2Fall%2Fportraits2021%2F2.2%20HG_84_blauesshirt.jpg?alt=media&token=f33d866c-d4dc-4979-b79a-37ae6846bf8a',
      'https://firebasestorage.googleapis.com/v0/b/hansgurbig-de-9fc49.appspot.com/o/pictures%2Fall%2Fportraits2021%2F16.%20HG_124_bomber_FAV.jpg?alt=media&token=5441fad9-7779-404f-a29f-2969c080b613',
      'https://firebasestorage.googleapis.com/v0/b/hansgurbig-de-9fc49.appspot.com/o/pictures%2Fall%2Fportraits2021%2F11.%20HG_96_hemd_FAV.jpg?alt=media&token=41152fbc-6deb-4544-bb41-43c32682c726',
      'https://firebasestorage.googleapis.com/v0/b/hansgurbig-de-9fc49.appspot.com/o/pictures%2Fall%2Fportraits2021%2F15.2%20HG_118_bomber_FAV.jpg?alt=media&token=61cd873d-73ee-4c0f-a86f-2919e1608dee',
      'https://firebasestorage.googleapis.com/v0/b/hansgurbig-de-9fc49.appspot.com/o/pictures%2Fall%2Fportraits2019%2FHans4sw.jpg?alt=media&token=3ca39dc0-e411-44ae-8587-296cd8a56ea1',
      'https://firebasestorage.googleapis.com/v0/b/hansgurbig-de-9fc49.appspot.com/o/pictures%2Fall%2Fportraits2019%2FHans1.jpg?alt=media&token=8db898c7-71de-46e6-9f55-c8169907d358',
      'https://firebasestorage.googleapis.com/v0/b/hansgurbig-de-9fc49.appspot.com/o/pictures%2Fall%2Fportraits2019%2FHans10.jpg?alt=media&token=0b7380d0-f4eb-4ae4-939a-b7d7c15005b4',
      'https://firebasestorage.googleapis.com/v0/b/hansgurbig-de-9fc49.appspot.com/o/pictures%2Fall%2Fportraits2019%2FHans3sw.jpg?alt=media&token=bbf8d67f-2e99-4c2e-8659-f14cf1d3b917',
      'https://firebasestorage.googleapis.com/v0/b/hansgurbig-de-9fc49.appspot.com/o/pictures%2Fall%2F001.jpg?alt=media&token=320dea34-4c17-4b12-80c0-4d5b27805aba',
      'https://firebasestorage.googleapis.com/v0/b/hansgurbig-de-9fc49.appspot.com/o/pictures%2Fall%2Fportraits2020%2F_DSC9811.jpg?alt=media&token=9e98f721-9376-4bae-b517-e12a0afb6477',
      'https://firebasestorage.googleapis.com/v0/b/hansgurbig-de-9fc49.appspot.com/o/pictures%2Fall%2Fportraits2020%2F_DSC1086.jpg?alt=media&token=9315dc56-2d78-4fe7-aed6-d0f31171d235',
      'https://firebasestorage.googleapis.com/v0/b/hansgurbig-de-9fc49.appspot.com/o/pictures%2Fall%2Fportraits2020%2FMK1_5000.jpg?alt=media&token=c9ecb916-ed21-4b8a-8441-c018b0a36959',
      'https://firebasestorage.googleapis.com/v0/b/hansgurbig-de-9fc49.appspot.com/o/pictures%2Fall%2Fportraits2020%2FIMG_2153.jpg?alt=media&token=e7d33eb8-d6b8-4632-85d8-15581dbc3538',
      'https://firebasestorage.googleapis.com/v0/b/hansgurbig-de-9fc49.appspot.com/o/pictures%2Fall%2Fportraits2020%2FHANS_GURBIG_Portrait-CL-5.jpg?alt=media&token=1c7fd66e-fa31-41ab-951c-c7d5adcb6fb7',
      'https://firebasestorage.googleapis.com/v0/b/hansgurbig-de-9fc49.appspot.com/o/pictures%2Fall%2F01.jpg?alt=media&token=0ec2fd66-82c4-4a95-a49c-e0399a8ab16e',
      'https://firebasestorage.googleapis.com/v0/b/hansgurbig-de-9fc49.appspot.com/o/pictures%2Fall%2F03.jpg?alt=media&token=9b700593-b652-43ba-adb6-c265334ed919',
      'https://firebasestorage.googleapis.com/v0/b/hansgurbig-de-9fc49.appspot.com/o/pictures%2Fall%2F04.jpg?alt=media&token=282780c2-bf05-439d-9657-4139cdc3d365',
      'https://firebasestorage.googleapis.com/v0/b/hansgurbig-de-9fc49.appspot.com/o/pictures%2Fall%2F05.jpg?alt=media&token=e78056f2-bb61-4c57-a497-861b6f1fc932',
      'https://firebasestorage.googleapis.com/v0/b/hansgurbig-de-9fc49.appspot.com/o/pictures%2Fall%2F13.jpg?alt=media&token=a8cda3eb-a0f2-4fca-b350-c63a80c1af41',
      'https://firebasestorage.googleapis.com/v0/b/hansgurbig-de-9fc49.appspot.com/o/pictures%2Fall%2F14.jpg?alt=media&token=b7885fcf-07d1-41c4-84b1-ada8bbada9e4'];

      imagesGallery_2:  any[] = [
        'https://firebasestorage.googleapis.com/v0/b/hansgurbig-de-9fc49.appspot.com/o/pictures%2Fall%2F%23projects%2F02.PNG?alt=media&token=2fe3d89e-1e29-4ede-b367-1d2f3cf391f3',
        'https://firebasestorage.googleapis.com/v0/b/hansgurbig-de-9fc49.appspot.com/o/pictures%2Fall%2F%23projects%2F01.PNG?alt=media&token=f454f027-eacb-4d2e-b308-12a08a61eb9c',
        'https://firebasestorage.googleapis.com/v0/b/hansgurbig-de-9fc49.appspot.com/o/pictures%2Fall%2F%23projects%2F01.JPG?alt=media&token=99ee008c-9601-4958-8d4f-600451bd6407',
        'https://firebasestorage.googleapis.com/v0/b/hansgurbig-de-9fc49.appspot.com/o/pictures%2Fall%2F%23projects%2F02.JPG?alt=media&token=0c3991bd-a548-424c-b1a2-2f7517989685',
        'https://firebasestorage.googleapis.com/v0/b/hansgurbig-de-9fc49.appspot.com/o/pictures%2Fall%2F%23projects%2F03.JPG?alt=media&token=0400e3ba-f6ff-4a78-9095-89510a7437dc',
        'https://firebasestorage.googleapis.com/v0/b/hansgurbig-de-9fc49.appspot.com/o/pictures%2Fall%2F%23projects%2Fhans1.JPG?alt=media&token=0d62e9ed-8fa3-4b2e-9088-b2e8d85f7dbd',
        'https://firebasestorage.googleapis.com/v0/b/hansgurbig-de-9fc49.appspot.com/o/pictures%2Fall%2F%23projects%2Fhans2.JPG?alt=media&token=235ce220-d89a-4d25-8f9a-7c025175e636',
        'https://firebasestorage.googleapis.com/v0/b/hansgurbig-de-9fc49.appspot.com/o/pictures%2Fall%2F%23projects%2Fhans3.JPG?alt=media&token=e77933e7-7002-4489-87c9-b167fc80284f',
        'https://firebasestorage.googleapis.com/v0/b/hansgurbig-de-9fc49.appspot.com/o/pictures%2Fall%2F%23projects%2Fhans4.JPG?alt=media&token=00b443de-bf05-4577-9044-b7990e0cd8bf',
        'https://firebasestorage.googleapis.com/v0/b/hansgurbig-de-9fc49.appspot.com/o/pictures%2Fall%2F%23projects%2Fhans5.JPG?alt=media&token=8df703f0-43e5-4c74-a603-a257dd099fa3',
        'https://firebasestorage.googleapis.com/v0/b/hansgurbig-de-9fc49.appspot.com/o/pictures%2Fall%2F%23projects%2Fhans6.JPG?alt=media&token=af53974e-c134-48a5-af45-181a45a1a464',
        'https://firebasestorage.googleapis.com/v0/b/hansgurbig-de-9fc49.appspot.com/o/pictures%2Fall%2F%23projects%2Fhans7.JPG?alt=media&token=1827b874-aa5c-4c79-b7d1-ae2a1dbc33bf',
        'https://firebasestorage.googleapis.com/v0/b/hansgurbig-de-9fc49.appspot.com/o/pictures%2Fall%2F%23projects%2Fhans8.jpg?alt=media&token=2095c6e5-2733-4545-9297-e7457db95d6f',
        'https://firebasestorage.googleapis.com/v0/b/hansgurbig-de-9fc49.appspot.com/o/pictures%2Fall%2F16.jpg?alt=media&token=991e01e6-add6-4269-b220-4e755cf2c533'];


      imagesGallery_3:  any[] = [
        'https://firebasestorage.googleapis.com/v0/b/hansgurbig-de-9fc49.appspot.com/o/pictures%2Fall%2F15.jpg?alt=media&token=5cd75fde-b09f-4e44-9946-649c17f9ec68',
        'https://firebasestorage.googleapis.com/v0/b/hansgurbig-de-9fc49.appspot.com/o/pictures%2Fall%2F%23stories%2Fhans1.JPG?alt=media&token=76361535-aeed-405f-a020-b061b961ec0f',
        'https://firebasestorage.googleapis.com/v0/b/hansgurbig-de-9fc49.appspot.com/o/pictures%2Fall%2F%23stories%2Fhans2.JPG?alt=media&token=08261c09-9cb9-451c-962b-8b5cf8fca5eb',
        'https://firebasestorage.googleapis.com/v0/b/hansgurbig-de-9fc49.appspot.com/o/pictures%2Fall%2F%23stories%2Fhans3.JPG?alt=media&token=ca1d977a-4761-48b9-aa73-f7e029a7f2db',
        'https://firebasestorage.googleapis.com/v0/b/hansgurbig-de-9fc49.appspot.com/o/pictures%2Fall%2F%23stories%2Fhans4.jpg?alt=media&token=a26ff49e-37d2-4ca2-bdb6-3a045ca52da7',
        'https://firebasestorage.googleapis.com/v0/b/hansgurbig-de-9fc49.appspot.com/o/pictures%2Fall%2F%23stories%2Fhans6.jpg?alt=media&token=24f055d2-aff3-4d1d-b331-cc0f56f0d663',
        'https://firebasestorage.googleapis.com/v0/b/hansgurbig-de-9fc49.appspot.com/o/pictures%2Fall%2F%23stories%2Fhans7.jpg?alt=media&token=ed6dfccd-5fa8-4229-b3ae-bc74e0323f21',
        'https://firebasestorage.googleapis.com/v0/b/hansgurbig-de-9fc49.appspot.com/o/pictures%2Fall%2F%23stories%2Fhans8.jpg?alt=media&token=c034e6c9-5eb1-4005-8cdc-bbbac181a32f'];

      videos:  any[] = [
        'https://firebasestorage.googleapis.com/v0/b/hansgurbig-de-9fc49.appspot.com/o/videos%2FAoN_klein.mp4?alt=media&token=d439115a-ffa2-4ce8-80e7-0183f5ec1f84',
        'https://firebasestorage.googleapis.com/v0/b/hansgurbig-de-9fc49.appspot.com/o/videos%2FHans%20Gurbig%20-%20Wahrheit%20oder%20Nicht.mp4?alt=media&token=4c06daea-9c5a-4f59-907b-d6bdb5de1aa7',
        'https://firebasestorage.googleapis.com/v0/b/hansgurbig-de-9fc49.appspot.com/o/videos%2FHans%20Gurbig%20-%20Nicht%20gut%20genug.mp4?alt=media&token=51cd5170-2f18-4b13-a912-028e2108a568'];

    showMain: boolean = false

    showMenu: boolean = true;

    showStill: boolean = false;

    showMove: boolean = false;

    showVoice: boolean = false;

    mobile: boolean = false;

    landscape: boolean = false;

    selection: string = "news";

    hammer: any;

    loading: boolean = true;

    category: number = 1;

    lastOverlay: boolean = false;

    @ViewChild('slideshow', { static: false }) slideshow: any;

    @ViewChild('slideshow1', { static: true }) slideshow1: any;

    @ViewChild('slideshow2', { static: true }) slideshow2: any;

    constructor() {

    }

    ngOnInit() {

      /** Firebase Init */

      /*
      var config = {
        apiKey: "AIzaSyBv2cU00hVkb_xygV_vSxGwau_L34uKLMc",
        authDomain: "luckypostcard.firebaseapp.com",
        databaseURL: "https://luckypostcard.firebaseio.com",
        projectId: "luckypostcard",
        storageBucket: "luckypostcard.appspot.com",
        messagingSenderId: "467489191266"
      };

      if (!firebase.apps.length) {
        firebase.initializeApp(config);
      }
      */

      /** Set up Hammerjs */

      var square = document.getElementById('overlay')

      this.hammer = new Hammer(square);

      this.hammer.on("swipeleft", (ev) => {
        this.move("left")
      });

      this.hammer.on("swiperight", (ev) => {
        this.move("right")
      });

      this.detectRotation()

      setTimeout(() => {
        var loadingDiv = <HTMLDivElement> document.getElementById("overlay-loading")
        loadingDiv.className = "overlay-loading animated fadeOut"
        setTimeout(() => {
          this.loading=false
        }, 1000);
      }, 2000);

      }

      detectRotation() {

        /** Check for landscape */

        if (innerWidth > 992) {
          this.mobile = false;
        } else {
          this.mobile = true;
          //this.loadImageUrls()
        }

        if(innerWidth > innerHeight) {
          this.landscape = true;
        } else {
          this.landscape = false;
        }

        console.log("Mobile Device: " + this.mobile)

        console.log("Landscape: " + this.landscape)


        if (this.mobile == true) {

          window.addEventListener("resize",() => {

            //Check if Landscape

              if(innerWidth > innerHeight) {
                this.landscape = true;

              } else {
                this.landscape = false;
              }



          }, false);

        }

      }

      loadImageUrls() {

        if (this.mobile==true) {

          this.imagesSlideShow = this.imagesSlideShow_mobile
          //this.imagesGallery = ['../assets/slideshow_1_mobile.jpg','../assets/slideshow_2_mobile.jpg','../assets/slideshow_3_mobile.jpg','../assets/slideshow_4_mobile.jpg','../assets/slideshow_5_mobile.jpg','../assets/slideshow_6_mobile.jpg'];

        } else {

          //this.imagesGallery = ['../assets/slideshow_1_mobile.jpg','../assets/slideshow_2_mobile.jpg','../assets/slideshow_3_mobile.jpg','../assets/slideshow_4_mobile.jpg','../assets/slideshow_5_mobile.jpg','../assets/slideshow_6_mobile.jpg'];

        }

      }

      closeOverlay() {

        console.log("Closing")

        if (this.showMove || this.showStill) {

          var overlay = <HTMLDivElement> document.getElementById("overlay-footage")
          overlay.className = "overlay-footage animated fadeOut"

          //var video1 = <HTMLVideoElement> document.getElementById("video1")
          //var video2 = <HTMLVideoElement> document.getElementById("video2")

          //video1.pause()
          //video2.pause()

          setTimeout(() => {

            if (this.showStill) {

              this.main('still')

            }

            if (this.showMove) {

              this.main('move')

            }

            this.showStill = false

            this.showMove = false;

            overlay.className = "overlay-footage animated fadeIn"


          }, 500);

        }

      }

      menu() {
        console.log("showStill: "+this.showStill)
        console.log("showMove: "+this.showMove)
        console.log("showMenu: "+this.showMenu)
        console.log("showMain: "+this.showMain)

        console.log("Category: "+this.category)


        setTimeout(() => {

          console.log("showStill: "+this.showStill)
          console.log("showMove: "+this.showMove)
          console.log("showMenu: "+this.showMenu)
          console.log("showMain: "+this.showMain)

          //this.showStill = true;

        }, 2000);

        console.log("Menu Triggered")
        //var button = document.getElementById("nav-icon3")

        var menu_container = document.getElementById("menu-container")

        var main = document.getElementById("main")

          if (this.showMenu == false) {

            if (this.mobile) {

              main.className = "main animated slideOutDown"

              setTimeout(() => {

                this.showMain = false;

              }, 500);


            }

            console.log("Show Menu")

            this.showMenu = true;

            menu_container.className = "menu-container animated fadeIn"

            //button.classList.toggle('open');



          } else if (this.showMenu == true) {

            console.log("Dismiss Menu")

            //button.classList.toggle('open');

            menu_container.className = "menu-container animated fadeOut"


            main.className = "main animated slideOutDown"

            setTimeout(() => {

            this.showMenu = false;

            this.showMain = false;

            }, 500);



      }
    }

      main(selection) {

        console.log("Button Pressed")

        console.log("showMain: "+this.showMain)

        console.log("Before selection: " + this.selection)

        console.log("Wanted selection: " + selection)

        var main = document.getElementById("main")

        var menu_container = document.getElementById("menu-container")

        if (this.showMain == false) {

          this.lastOverlay = false

          this.selection = selection

          if (this.mobile) {

            this.showMenu = false;

          }

          main.className = "main animated slideInUp"

          console.log("main animated -> Entrance")

          console.log("New selection: " + this.selection)

          //.slideshow.goToSlide(2);

          this.showMain = true;

        } else if (this.showMain == true && this.lastOverlay == true) {

          this.selection = selection

          if (this.mobile) {

            this.lastOverlay = false

            menu_container.className = "menu-container animated fadeOut"

            this.showMenu = false;
          }

          main.className = "main animated slideInUp"

          console.log("main animated -> Entrance")

          console.log("New selection: " + this.selection)

          //.slideshow.goToSlide(2);

          this.showMain = true;

        } else if (this.showMain == true && this.lastOverlay == false) {

          if (this.selection == selection) {

            main.className = "main animated slideOutDown"

            setTimeout(() => {

            this.showMain = false;

            console.log("main animated -> Leave")

            console.log("New selection: " + this.selection)


            }, 1200);

          } else {

            this.selection = selection

            console.log("New selection: " + this.selection)

          }



        }

      }

      move(direction: string) {

        console.log("test")

        console.log(this.imageCounter)

        if (this.category == 1) {

          var numberImages = this.imagesGallery_1.length-1

        }

        if (this.category == 2) {

          var numberImages = this.imagesGallery_2.length-1

        }

        if (this.category == 3) {

          var numberImages = this.imagesGallery_3.length-1

        }


        var numberVideos = this.videos.length-1


        if (direction == "left") {

          this.imageCounter = this.imageCounter-1

        } else if (direction == "right") {

          this.imageCounter = this.imageCounter+1

        }

        if (this.showStill == true) {

          if (this.imageCounter>numberImages) {
            this.imageCounter=0
          } else if (this.imageCounter<0) {
            this.imageCounter=numberImages
          }

          console.log(this.imageCounter)
          console.log(numberImages)

          this.slideshow1.goToSlide(this.imageCounter);
          this.slideshow2.goToSlide(this.imageCounter);

          /*
          var image = <HTMLImageElement> document.getElementById("imageBig")

          image.src = this.imageBigUrls[this.imageCounter]*/

        } else if (this.showMove == true) {

          if (this.imageCounter>numberVideos) {
            this.imageCounter=0
          } else if (this.imageCounter<0) {
            this.imageCounter=numberVideos
          }

          var video1 = <HTMLVideoElement> document.getElementById("video1")
          var video2 = <HTMLVideoElement> document.getElementById("video2")

          video1.src = this.videos[this.imageCounter]
          video2.src = this.videos[this.imageCounter]

        }

      }

      // audio player

      @ViewChild(PlyrComponent, {static: true})
      plyr: PlyrComponent;

      // Audio Dateien von Firebase storage
      audioSourceOne = [
        {
          src: 'https://firebasestorage.googleapis.com/v0/b/hansgurbig-de-9fc49.appspot.com/o/audio%2FWerbung_Demo_1.mp3?alt=media&token=cabc92af-e04a-4d7d-81db-21a6862b7ca6',
          type: 'audio/mpeg',
        }
      ];

      /*
      ÄNDERN = [
        {
          src: 'https://firebasestorage.googleapis.com/....';
          type: 'audio/mpeg',
        }
      ];
       */

      audioSourceTwo = [
        {
          src: 'https://firebasestorage.googleapis.com/v0/b/hansgurbig-de-9fc49.appspot.com/o/audio%2FWerbung_Demo_2.mp3?alt=media&token=0f665721-4fb5-4f95-9b6c-44cea77dbc47',
          type: 'audio/mpeg',
        }
      ];

      audioSourceThree = [
        {
          src: 'https://firebasestorage.googleapis.com/v0/b/hansgurbig-de-9fc49.appspot.com/o/audio%2FWerbung_Demo_3.mp3?alt=media&token=428abf47-4252-4710-a3d0-d70d2e7b7f29',
          type: 'audio/mpeg',
        }
      ];

      played(event: Plyr.PlyrEvent) {
        console.log('played', event);
      }

      play(): void {
        this.plyr.player.play();
      }

      pause(): void {
        this.plyr.player.pause(); 
      }
    
      stop(): void {
        this.plyr.player.stop(); 
      }

      stopPropogation(e: Event){
        e.stopPropagation();
      }
   

    }



